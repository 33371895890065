// Copyright (C) 2022 by Posit Software, PBC.

import AccessTypes from './accessType';
import AppModes from './appMode';
import AppRoles from './appRole';
import BuildStatus from './buildStatus';
import { Group } from './group';
import { JobTag } from './job';
import PublicContentStatuses from './publicContentStatus';
import { User } from './user';

// ContentType enumerates the kind of applications we support
export const ContentType = {
  Unknown: 'unknown',
  Application: 'application',
  Document: 'document',
  Site: 'site',
  Plot: 'plot',
  Pin: 'pin',
  Api: 'api',
  TensorFlowApi: 'tensorFlowApi',
};

export const ContentTypeLabel = {
  [ContentType.Unknown]: 'item',
  [ContentType.Application]: 'application',
  [ContentType.Document]: 'document',
  [ContentType.Site]: 'site',
  [ContentType.Plot]: 'plot',
  [ContentType.Pin]: 'pin',
  [ContentType.Api]: 'API',
  [ContentType.TensorFlowApi]: 'TensorFlow Model API',
};

const staticContentType = (appMode, category) => {
  // book used in the past
  if (['book', 'site'].includes(category)) {
    return ContentType.Site;
  }
  if (appMode !== AppModes.Static) {
    return ContentType.Document;
  }
  switch (category) {
    case 'plot':
      return ContentType.Plot;
    case 'pin':
      return ContentType.Pin;
    default:
      return ContentType.Document;
  }
};

// App reflects the backend struct `store.FullAppRecord`.
export class App {
  /* eslint-disable no-shadow */
  // eslint-disable-next-line complexity
  constructor({
    id,
    guid,
    accessType,
    publicContentStatus,
    extension = false,
    locked = false,
    lockedMessage = '',
    connectionTimeout = null,
    clusterName = null,
    imageName = null,
    defaultImageName = null,
    readTimeout = null,
    initTimeout = null,
    idleTimeout = null,
    maxProcesses = null,
    minProcesses = null,
    maxConnsPerProcess = null,
    loadFactor = null,
    cpuLimit = null,
    cpuRequest = null,
    memoryLimit = null,
    memoryRequest = null,
    amdGpuLimit = null,
    nvidiaGpuLimit = null,
    url,
    vanityUrl,
    name,
    title = null,
    bundleId = null,
    appMode,
    contentCategory,
    hasParameters,
    createdTime,
    lastDeployedTime,
    rVersion = null,
    pyVersion = null,
    quartoVersion = null,
    rEnvironmentManagement = null,
    defaultREnvironmentManagement = null,
    pyEnvironmentManagement = null,
    defaultPyEnvironmentManagement = null,
    buildStatus,
    runAs = null,
    runAsCurrentUser,
    serviceAccountName = null,
    description,
    appRole,
    ownerFirstName = null,
    ownerLastName = null,
    ownerUsername = null,
    ownerGuid,
    ownerEmail = null,
    ownerLocked,
    isScheduled,
    git = null,
    users = [],
    groups = [],
    vanities = [],
  } = {}) {
    /* eslint-enable no-shadow */
    this.id = id;
    this.guid = guid;
    this.accessType = AccessTypes.of(accessType);
    this.publicContentStatus = PublicContentStatuses.of(
      publicContentStatus
    );
    this.extension = extension,
    this.locked = locked;
    this.lockedMessage = lockedMessage;
    this.connectionTimeout = connectionTimeout;
    this.clusterName = clusterName;
    this.imageName = imageName;
    this.defaultImageName = defaultImageName;
    this.readTimeout = readTimeout;
    this.initTimeout = initTimeout;
    this.idleTimeout = idleTimeout;
    this.maxProcesses = maxProcesses;
    this.minProcesses = minProcesses;
    this.maxConnsPerProcess = maxConnsPerProcess;
    this.loadFactor = loadFactor;
    this.cpuLimit = cpuLimit;
    this.cpuRequest = cpuRequest;
    this.memoryLimit = memoryLimit;
    this.memoryRequest = memoryRequest;
    this.amdGpuLimit = amdGpuLimit;
    this.nvidiaGpuLimit = nvidiaGpuLimit;
    this.url = url;
    this.vanityUrl = vanityUrl;
    this.name = name;
    this.title = title;
    this.bundleId = bundleId ? String(bundleId) : null;
    this.appMode = AppModes.of(appMode);
    this.contentCategory = contentCategory;
    this.hasParameters = hasParameters;
    this.createdTime = new Date(createdTime);
    this.lastDeployedTime = new Date(lastDeployedTime);
    this.rVersion = rVersion;
    this.pyVersion = pyVersion;
    this.quartoVersion = quartoVersion;
    this.rEnvironmentManagement = rEnvironmentManagement;
    this.defaultREnvironmentManagement = defaultREnvironmentManagement;
    this.pyEnvironmentManagement = pyEnvironmentManagement;
    this.defaultPyEnvironmentManagement = defaultPyEnvironmentManagement;
    this.buildStatus = BuildStatus.of(buildStatus);
    this.runAs = runAs;
    this.runAsCurrentUser = runAsCurrentUser;
    this.serviceAccountName = serviceAccountName;
    this.description = description;
    this.appRole = AppRoles.of(appRole);
    this.ownerFirstName = ownerFirstName;
    this.ownerLastName = ownerLastName;
    this.ownerUsername = ownerUsername;
    this.ownerGuid = ownerGuid;
    this.ownerEmail = ownerEmail;
    this.ownerLocked = ownerLocked;
    this.isScheduled = isScheduled;
    this.git = git;
    this.users = (users || []).map(user => new User(user));
    this.groups = (groups || []).map(group => new Group(group));
    this.vanities = vanities || [];

    this.displayName = title || name;
  }

  /* eslint-disable no-shadow */
  // eslint-disable-next-line complexity
  static fromV1({
    id,
    guid,
    accessType,
    extension = false,
    locked = false,
    lockedMessage = '',
    connectionTimeout = null,
    clusterName = null,
    imageName = null,
    defaultImageName = null,
    readTimeout = null,
    initTimeout = null,
    idleTimeout = null,
    maxProcesses = null,
    minProcesses = null,
    maxConnsPerProcess = null,
    loadFactor = null,
    contentUrl,
    name,
    title = null,
    bundleId = null,
    appMode,
    contentCategory,
    parameterized,
    createdTime,
    lastDeployedTime,
    rVersion = null,
    pyVersion = null,
    quartoVersion = null,
    rEnvironmentManagement = null,
    defaultREnvironmentManagement = null,
    pyEnvironmentManagement = null,
    defaultPyEnvironmentManagement = null,
    runAs = null,
    runAsCurrentUser,
    serviceAccountName = null,
    description,
    appRole,
    ownerGuid,
    owner = null,
  } = {}) {
    const ownerPayload = {};
    if (owner) {
      ownerPayload.ownerFirstName = owner.firstName;
      ownerPayload.ownerLastName = owner.lastName;
      ownerPayload.ownerUsername = owner.username;
      ownerPayload.ownerGuid = owner.guid;
    }

    /* eslint-enable no-shadow */
    return new App({
      id,
      guid,
      accessType,
      extension,
      locked,
      lockedMessage,
      connectionTimeout,
      clusterName,
      imageName,
      defaultImageName,
      readTimeout,
      initTimeout,
      idleTimeout,
      maxProcesses,
      minProcesses,
      maxConnsPerProcess,
      loadFactor,
      cpuLimit: null,
      cpuRequest: null,
      memoryLimit: null,
      memoryRequest: null,
      amdGpuLimit: null,
      nvidiaGpuLimit: null,
      url: contentUrl,
      name,
      title,
      bundleId,
      appMode,
      contentCategory,
      hasParameters: parameterized,
      createdTime,
      lastDeployedTime,
      rVersion,
      pyVersion,
      quartoVersion,
      rEnvironmentManagement,
      defaultREnvironmentManagement,
      pyEnvironmentManagement,
      defaultPyEnvironmentManagement,
      runAs,
      runAsCurrentUser,
      serviceAccountName,
      description,
      appRole,
      ownerGuid,
      ...ownerPayload,
    });
  }

  toJSON() {
    return {
      id: this.id,
      guid: this.guid,
      accessType: this.accessType,
      extension: this.extension,
      locked: this.locked,
      lockedMessage: this.lockedMessage,
      clusterName: this.clusterName,
      imageName: this.imageName,
      defaultImageName: this.defaultImageName,
      // timeout values are in nanoseconds
      connectionTimeout: this.connectionTimeout,
      readTimeout: this.readTimeout,
      initTimeout: this.initTimeout,
      idleTimeout: this.idleTimeout,
      maxProcesses: this.maxProcesses,
      minProcesses: this.minProcesses,
      maxConnsPerProcess: this.maxConnsPerProcess,
      loadFactor: this.loadFactor,
      cpuLimit: this.cpuLimit,
      cpuRequest: this.cpuRequest,
      memoryLimit: this.memoryLimit,
      memoryRequest: this.memoryRequest,
      amdGpuLimit: this.amdGpuLimit,
      nvidiaGpuLimit: this.nvidiaGpuLimit,
      url: this.url,
      vanityUrl: this.vanityUrl,
      name: this.name,
      title: this.title,
      bundleId: this.bundleId,
      appMode: this.appMode,
      contentCategory: this.contentCategory,
      hasParameters: this.hasParameters,
      createdTime: this.createdTime,
      lastDeployedTime: this.lastDeployedTime,
      rVersion: this.rVersion,
      pyVersion: this.pyVersion,
      quartoVersion: this.quartoVersion,
      rEnvironmentManagement: this.rEnvironmentManagement,
      defaultREnvironmentManagement: this.defaultREnvironmentManagement,
      pyEnvironmentManagement: this.pyEnvironmentManagement,
      defaultPyEnvironmentManagement: this.defaultPyEnvironmentManagement,
      buildStatus: this.buildStatus,
      runAs: this.runAs,
      runAsCurrentUser: this.runAsCurrentUser,
      serviceAccountName: this.serviceAccountName,
      description: this.description,
      appRole: AppRoles.stringOf(this.appRole),
      ownerFirstName: this.ownerFirstName,
      ownerLastName: this.ownerLastName,
      ownerUsername: this.ownerUsername,
      ownerGuid: this.ownerGuid,
      ownerEmail: this.ownerEmail,
      ownerLocked: this.ownerLocked,
      isScheduled: this.isScheduled,
      git: this.git,
      users: this.users,
      groups: this.groups,
      vanities: this.vanities,
    };
  }

  toString() {
    return `App(${this.guid})`;
  }

  hasRuntimeSettings() {
    return [
      AppModes.Shiny,
      AppModes.ShinyRmd,
      AppModes.PlumberApi,
      AppModes.TensorFlowApi,
      AppModes.PythonApi,
      AppModes.PythonDash,
      AppModes.PythonStreamlit,
      AppModes.PythonBokeh,
      AppModes.PythonFastAPI,
      AppModes.PythonShiny,
      AppModes.ShinyQuarto,
      AppModes.StaticRmd,
      AppModes.StaticJupyter,
      AppModes.StaticQuarto,
      AppModes.JupyterVoila,
    ].includes(AppModes.of(this.appMode));
  }

  hasWorker() {
    return [
      AppModes.Shiny,
      AppModes.ShinyRmd,
      AppModes.PlumberApi,
      AppModes.TensorFlowApi,
      AppModes.PythonApi,
      AppModes.PythonDash,
      AppModes.PythonStreamlit,
      AppModes.PythonBokeh,
      AppModes.PythonFastAPI,
      AppModes.PythonShiny,
      AppModes.ShinyQuarto,
      AppModes.JupyterVoila,
    ].includes(AppModes.of(this.appMode));
  }

  isExecutable() {
    return [
      AppModes.Shiny,
      AppModes.ShinyRmd,
      AppModes.StaticRmd,
      AppModes.PlumberApi,
      AppModes.TensorFlowApi,
      AppModes.StaticJupyter,
      AppModes.PythonApi,
      AppModes.PythonDash,
      AppModes.PythonStreamlit,
      AppModes.PythonBokeh,
      AppModes.PythonFastAPI,
      AppModes.PythonShiny,
      AppModes.ShinyQuarto,
      AppModes.StaticQuarto,
      AppModes.JupyterVoila,
    ].includes(AppModes.of(this.appMode));
  }

  isRunnableAsCurrentUser() {
    return [
      AppModes.Shiny,
      AppModes.ShinyRmd,
      AppModes.PythonDash,
      AppModes.PythonStreamlit,
      AppModes.PythonBokeh,
      AppModes.PythonShiny,
      AppModes.ShinyQuarto,
      AppModes.JupyterVoila,
    ].includes(AppModes.of(this.appMode));
  }

  isStatic() {
    return AppModes.of(this.appMode) === AppModes.Static;
  }

  isRenderable() {
    return [
      AppModes.StaticRmd,
      AppModes.StaticJupyter,
      AppModes.StaticQuarto,
    ].includes(AppModes.of(this.appMode));
  }

  isDeployed() {
    return this.bundleId !== null;
  }

  isSite() {
    // NOTE: ported from Angular (we briefly allowed content category of book)
    return this.contentCategory === 'site' || this.contentCategory === 'book';
  }

  contentDisplayName() {
    if (this.displayName) {
      if (staticContentType(AppModes.of(this.appMode), this.contentCategory) === ContentType.Pin) {
        return `${this.displayName} (${this.ownerUsername}/${this.name})`;
      }
      return this.displayName;
    }
    return null;
  }

  jobTag() {
    switch (AppModes.of(this.appMode)) {
      case AppModes.StaticJupyter:
      case AppModes.PythonApi:
      case AppModes.PythonDash:
      case AppModes.PythonStreamlit:
      case AppModes.PythonBokeh:
      case AppModes.PythonFastAPI:
      case AppModes.PythonShiny:
      case AppModes.JupyterVoila:
        return JobTag.PythonRestoreTag;
      default:
        return JobTag.PackratRestoreTag;
    }
  }

  contentType() {
    switch (AppModes.of(this.appMode)) {
      case AppModes.Unknown:
        return ContentType.Unknown;

      case AppModes.Shiny:
      case AppModes.PythonDash:
      case AppModes.PythonStreamlit:
      case AppModes.PythonBokeh:
      case AppModes.PythonShiny:
        return ContentType.Application;

      case AppModes.ShinyRmd:
      case AppModes.ShinyQuarto:
      case AppModes.JupyterVoila:
        return ContentType.Document;

      case AppModes.StaticRmd:
      case AppModes.StaticJupyter:
      case AppModes.StaticQuarto:
        return staticContentType(this.appMode, this.contentCategory);

      case AppModes.Static:
        return staticContentType(this.appMode, this.contentCategory);

      case AppModes.PlumberApi:
      case AppModes.PythonApi:
      case AppModes.PythonFastAPI:
        return ContentType.Api;

      case AppModes.TensorFlowApi:
        return ContentType.TensorFlowApi;

      default:
        return ContentType.Unknown;
    }
  }

  thumbnailType() {
    switch (AppModes.of(this.appMode)) {
      case AppModes.ShinyRmd:
      case AppModes.StaticRmd:
      case AppModes.StaticJupyter:
      case AppModes.ShinyQuarto:
      case AppModes.StaticQuarto:
        return 'doc';

      case AppModes.Static:
        switch (this.contentCategory) {
          case 'plot':
            return 'plot';
          case 'pin':
            return 'pin';
          default:
            return 'doc';
        }

      case AppModes.PlumberApi:
      case AppModes.PythonApi:
        return 'api';

      case AppModes.TensorFlowApi:
        return 'model';

      default:
        return 'app';
    }
  }

  /* eslint-disable complexity */
  iconClass() {
    const contentType = this.contentType();

    switch (AppModes.of(this.appMode)) {
      case AppModes.Unknown:
        return 'typeUnpublished';

      case AppModes.Shiny:
      case AppModes.PythonDash:
      case AppModes.PythonStreamlit:
      case AppModes.PythonBokeh:
      case AppModes.PythonShiny:
        return 'typeApp';

      case AppModes.ShinyRmd:
      case AppModes.ShinyQuarto:
      case AppModes.JupyterVoila:
        return 'typeShinyDoc';

      case AppModes.StaticRmd:
      case AppModes.StaticQuarto: {
        if (contentType === ContentType.Site) {
          if (this.isScheduled && this.hasParameters) {
            return 'typeSiteParamsScheduled';
          } else if (this.isScheduled) {
            return 'typeSiteScheduled';
          } else if (this.hasParameters) {
            return 'typeSiteParams';
          }
          return 'typeSite';
        }

        if (this.isScheduled && this.hasParameters) {
          return 'typeDocParamsScheduled';
        } else if (this.isScheduled) {
          return 'typeDocScheduled';
        } else if (this.hasParameters) {
          return 'typeDocParams';
        }
        return 'typeDoc';
      }

      case AppModes.Static: {
        switch (contentType) {
          case ContentType.Site:
            return 'typeSite';
          case ContentType.Plot:
            return 'typePlot';
          case ContentType.Pin:
            return 'typePin';
          default:
            // static documents get the same icon as R Markdown Documents - not ideal
            return 'typeDoc';
        }
      }

      case AppModes.PlumberApi:
      case AppModes.PythonApi:
      case AppModes.PythonFastAPI:
        return 'typeAPI';

      case AppModes.TensorFlowApi:
        return 'typeTensorFlow';

      case AppModes.StaticJupyter: {
        if (this.isScheduled) {
          return 'typeJupyterScheduled';
        }
        return 'typeJupyter';
      }

      default:
        return 'typeUnpublished';
    }
  }
  /* eslint-enable complexity */
}

<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { getBundleDownloadUrl } from '@/api/bundle';
import CopyIconButton from '@/components/CopyIconButton.vue';
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';
import {
  DELETE_BUNDLE,
  DEPLOY_BUNDLE,
  SELECT_BUNDLE,
} from '@/store/modules/bundles';
import { humanizeBytesDecimal } from '@/utils/bytes.filter';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
import BundleLog from './BundleLog';

defineEmits(['sourceVersions']);

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);

const store = useStore();

const localState = reactive({
  isDeleting: false,
  showingLogs: false,
  currentIndex: 0,
});

const activeBundle = computed(() =>
  store.state.bundles.items.find(b => b.active));
const activeBundleId = computed(() => Number(activeBundle.value?.id) || null);
const activateButtonLabel = computed(() =>
  activeBundleId.value === selectedBundleId.value ? 'Reactivate' : 'Activate');
const app = computed(() => store.state.contentView.app);
const bundles = computed(() => store.state.bundles.items);
const disableActions = computed(
  () => !selectedBundleId.value || app.value.locked
);
const disableDelete = computed(
  () =>
    !selectedBundleId.value ||
    activeBundleId.value === selectedBundleId.value ||
    bundles.value.length === 1
);
const selectedBundle = computed(() =>
  bundles.value.find(({ id }) => Number(id) === selectedBundleId.value));
const selectedBundleId = computed(() => store.state.bundles.selected.bundleId);

const selectBundle = bundleId => {
  store.commit(SELECT_BUNDLE, bundleId);
};
const deleteBundle = async({ appGuid, bundleId }) => {
  await store.dispatch(DELETE_BUNDLE, { appGuid, bundleId });
};
const deployBundle = ({ appId, bundleId }) => {
  store.dispatch(DEPLOY_BUNDLE, { bundleId, appId });
};

const isSelected = bundle => Number(bundle.id) === selectedBundleId.value;

const publishedText = when => {
  const mnt = dayjs(when);
  return `Published ${mnt.fromNow()}`;
};

const branchCommitTime = commitTime => dayjs(commitTime).local().format('llll');

const bundleSize = size => humanizeBytesDecimal(size);

const onClick = (index, id) => {
  if (localState.isDeleting) {
    return;
  }
  localState.currentIndex = index;
  selectBundle(id);
};

const isBitBucket = repo => repo.match(/https?:\/\/bitbucket\.org\/.+/);
const isGitSource = bundle => bundle?.metadata?.source === 'git';
const repoUrl = repo => repo?.replace(/\.git$/, '');
const branchUrl = bundle => {
  const treeToken = isBitBucket(bundle?.metadata?.sourceRepo) ? 'src' : 'tree';
  return `${repoUrl(bundle?.metadata?.sourceRepo)}/${treeToken}/${
    bundle?.metadata?.sourceBranch
  }`;
};
const commitUrl = ({ metadata: { sourceRepo, sourceCommit } }) => {
  const commitToken = isBitBucket(sourceRepo) ? 'commits' : 'commit';

  return `${repoUrl(sourceRepo).replace(/\.git$/, '')}/${commitToken}/${sourceCommit}`;
};

const onViewCommit = bundleId => {
  const bundle = bundles.value.find(b => Number(b.id) === Number(bundleId));
  if (!bundle) {
    return;
  }

  window.open(commitUrl(bundle), '_blank');
};
const downloadUrl = bundleId => getBundleDownloadUrl(app.value.guid, bundleId);
const onDeleteBundle = async bundleId => {
  await deleteBundle({ appGuid: app.value.guid, bundleId });
  localState.isDeleting = false;
};
const publishBundle = () => {
  deployBundle({
    bundleId: selectedBundleId.value,
    appId: app.value.id,
  });
  localState.showingLogs = true;
};

const onArrowUp = () => {
  const currentIndex = localState.currentIndex - 1;
  localState.currentIndex =
    currentIndex < 0 ? bundles.value.length - 1 : currentIndex;

  selectBundle(bundles.value[localState.currentIndex].id);
};
const onArrowDown = () => {
  const currentIndex = localState.currentIndex + 1;
  localState.currentIndex =
    currentIndex > bundles.value.length - 1 ? 0 : currentIndex;
  selectBundle(bundles.value[localState.currentIndex].id);
};
</script>
<template>
  <div v-if="!!app">
    <BundleLog
      v-if="localState.showingLogs"
      :selected-bundle-id="selectedBundleId"
      @close="localState.showingLogs = false"
    />
    <RSModal
      v-else-if="!!bundles"
      :active="true"
      subject="Source Versions"
      data-automation="source-versions-modal"
      @close="$emit('sourceVersions')"
    >
      <template #content>
        <div class="bundle-list">
          <!-- eslint-disable vuejs-accessibility/interactive-supports-focus -->
          <ul
            role="menu"
            tabindex="0"
            @keydown.down="onArrowDown"
            @keydown.up="onArrowUp"
          >
            <li
              v-for="(bundle, index) in bundles"
              :key="bundle.id"
              :ref="`option-${index}`"
              :class="['item', { selected: isSelected(bundle) }]"
              role="menuitem"
              @click="onClick(index, bundle.id)"
              @keyup.enter="onClick(index, bundle.id)"
            >
              <div
                class="active-version"
                :aria-label="bundle.active ? 'Active Bundle' : ''"
                :title="bundle.active ? 'Active' : ''"
              >
                {{ bundle.active ? '✔' : '' }}
              </div>

              <!-- eslint-enable vuejs-accessibility/interactive-supports-focus -->
              <div class="item version">
                <div
                  class="version-info"
                  data-automation="source-version__version-id"
                >
                  <span
                    class="pId"
                    data-automation="source-version__bundle-id"
                  >
                    {{ bundle.id }}
                  </span>
                  <span v-if="bundle.size">
                    ({{ bundleSize(bundle.size) }})</span>
                  <span v-if="!bundle.size">
                    &mdash; Unable to determine size</span>
                </div>
                <div class="operation">
                  {{ publishedText(bundle.createdTime) }}
                </div>
              </div>

              <RSButton
                v-if="isGitSource(bundle)"
                class="view-commit-button"
                data-automation="view-commit-button"
                type="link"
                label="View commit"
                size="small"
                @click="onViewCommit(bundle.id)"
              />
            </li>
          </ul>
        </div>

        <div
          v-if="app.git"
          class="git-details"
          data-automation="git-details"
        >
          <h2 class="git-details__title">
            Git Details
          </h2>
          <dl class="git-details__list">
            <dt>Repository</dt>
            <dd data-automation="git-details-repository">
              <a
                :href="repoUrl(selectedBundle?.metadata?.sourceRepo)"
                target="_blank"
              >{{ repoUrl(selectedBundle?.metadata?.sourceRepo) }}</a>
              <CopyIconButton :copy-text="repoUrl(selectedBundle?.metadata?.sourceRepo)" />
            </dd>

            <dt>Branch</dt>
            <dd data-automation="git-details-branch">
              <a
                :href="branchUrl(selectedBundle)"
                target="_blank"
              >
                {{ selectedBundle?.metadata?.sourceBranch }}
              </a>
              <CopyIconButton :copy-text="selectedBundle?.metadata?.sourceBranch" />
            </dd>

            <dt>Commit Date</dt>
            <dd
              class="date"
              data-automation="git-details-commit-date"
            >
              {{ branchCommitTime(selectedBundle?.createdTime) }}
            </dd>

            <dt>Commit SHA</dt>
            <dd
              class="mono"
              data-automation="git-details-commit-sha"
            >
              <a
                :href="commitUrl(selectedBundle)"
                target="_blank"
              >
                {{ selectedBundle?.metadata?.sourceCommit }}
              </a>
              <CopyIconButton
                :copy-text="selectedBundle?.metadata?.sourceCommit"
              />
            </dd>
          </dl>
        </div>
      </template>

      <template #controls>
        <div
          v-if="!localState.isDeleting"
          class="actions flex"
        >
          <div class="actionBar showTitles">
            <a
              :href="!selectedBundleId ? '' : downloadUrl(selectedBundleId)"
              class="action download"
              :class="{ disabled: disableActions }"
              data-automation="bundle-download"
              title="Download"
            >
              <span class="actionTitle">Download</span>
            </a>
            <button
              :disabled="disableActions"
              :class="{ disabled: disableActions }"
              title="View Log"
              class="action viewLog actionTitle"
              data-automation="bundle-logs"
              @click="localState.showingLogs = true"
            >
              View Log
            </button>
            <button
              :disabled="disableDelete"
              :class="{ disabled: disableDelete }"
              title="Delete"
              class="action delete actionTitle"
              data-automation="bundle-delete"
              @click="localState.isDeleting = true"
            >
              Delete
            </button>
          </div>
          <RSButton
            type="primary"
            :disabled="disableActions"
            :class="{ disabled: disableActions }"
            :label="activateButtonLabel"
            :title="activateButtonLabel"
            data-automation="bundle-publish"
            @click="publishBundle"
          >
            {{ activateButtonLabel }}
          </RSButton>
        </div>

        <div
          v-else
          class="actions"
        >
          <div class="actionBar delete-controls">
            <p class="delete-prompt">
              Delete this permanently?
            </p>

            <div class="confirm-buttons">
              <RSButton
                label="No"
                type="primary"
                :use-label-width="true"
                data-automation="delete-source-cancel__button"
                @click="localState.isDeleting = false"
              />
              <RSButton
                label="Yes"
                type="secondary"
                :use-label-width="true"
                data-automation="delete-source-version__button"
                @click="onDeleteBundle(selectedBundleId)"
              />
            </div>
          </div>
        </div>
      </template>
    </RSModal>
  </div>
</template>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.bundle-list {
  @include control-visible-focus;
  border: 1px solid $color-medium-grey;
  border-radius: 3px;
  height: 20rem;
  overflow-y: auto;

  &:has(:focus-visible) {
    @include control-focus(3px);
  }

  ul:focus-visible {
    outline: none;
  }

  li.item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
    padding: 0.25rem 1rem;
    width: 100%;

    &.selected {
      background-color: $color-primary;
      color: $color-white;

      .active-version {
        color: #cbfe93; // green with sufficient contrast with the background.
      }

      .rs-button.link {
        color: $color-white;

        &:hover {
          color: $color-primary-dark;
        }
      }
    }

    .active-version {
      align-items: center;
      color: $color-posit-green;
      display: flex;
      font-size: 1.5rem;
      min-width: 25px;
    }

    .version {
      align-items: flex-start;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      margin-left: 1rem;
    }

    .operation {
      font-size: 0.8rem;
      font-style: italic;
    }

    .rs-button.link {
      color: $color-primary;
    }
  }
}

.git-details {
  &__title {
    color: $color-dark-grey;
    font-size: 0.9rem;
    font-weight: normal;
    margin-top: 1rem;
    text-align: left;
  }

  &__list {
    background-color: #f0f0f0;
    border-radius: 3px;
    border: 1px solid $color-medium-grey;
    display: grid;
    grid-gap: 0 1rem;
    grid-template-columns: max-content;
    padding: 0.5rem 1rem;
    width: 100%;

    dt {
      align-items: center;
      display: flex;
      font-weight: 600;
      line-height: 25px;
    }

    dd {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      grid-column-start: 2;
      line-height: 25px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      padding: 0 0.2rem;

      &.date {
        font-size: 0.8rem;
      }

      &.mono {
        font-family: monospace;
        font-size: 0.8rem;
      }

      a {
        margin: 0.2rem;
      }
    }
  }
}

.actions {
  width: 100%;
  .actionBar {
    padding-left: 0;
  }

  .delete-controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .confirm-buttons {
      display: flex;
      justify-content: space-between;

      .rs-button {
        margin-left: 1rem;
      }
    }
  }

  .delete-prompt {
    font-size: 1.1rem;
    font-weight: 600;
  }
}
</style>

<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { onMounted, computed, ref } from 'vue';

import SlidingToggle from '@/components/SlidingToggle.vue';
import CopyButton from '@/components/CopyButton.vue';
import RSInformationToggle from '@/elements/RSInformationToggle';
import ConfirmModal from '@/components/ConfirmModal';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';

import {
  getShare,
  createShare,
  removeShare,
} from '@/api/share';

const props = defineProps({
  app: { type: Object, required: true },
});

const confirmDelete = ref(false);
const share = ref(undefined);
const isShared = computed({
  get() {
    return !!share.value;
  },
  set() {
    toggleShare();
  }
});
const shareUrl = computed(() => isShared.value ? share.value.url() : '');

const onDelete = () => {
  removeShare(props.app.guid).then((response) => {
    if (response.status === 200) {
      share.value = undefined;
    }
    confirmDelete.value = false;
  });
};

const toggleShare = () => {
  if (isShared.value) {
    confirmDelete.value = true;
  } else {
    createShare(props.app.guid).then((response) => {
      if (response.share) {
        share.value = response.share;
      }
    });
  }
};

onMounted(() => {
  getShare(props.app.guid).then((response) => {
    if (response.share) {
      share.value = response.share;
    }
  });
});
</script>

<template>
  <div class="share-link">
    <RSInformationToggle>
      <template #title>
        <SlidingToggle
          v-model="isShared"
          data-automation="share-link__slider"
          color="#447099"
          label="Create private link to share"
        />
      </template>
      <template #help>
        <p>
          Anyone with this link will be able to view the content anonymously. The link does not expire until it is turned off.
        </p>
      </template>
    </RSInformationToggle>

    <ConfirmModal
      v-if="confirmDelete"
      subject="Remove Share Link"
      confirmation="Remove"
      @confirm="onDelete"
      @cancel="confirmDelete = false"
    >
      <EmbeddedStatusMessage
        type="error"
        class="share-link__delete-warning"
        message="Upon removal, the existing share URL will no longer provide anonymous access to this content."
        :show-close="false"
      />

      <p class="share-link__delete-confirm">
        Are you sure that you want to remove the existing share link?
      </p>
    </ConfirmModal>

    <div
      v-if="isShared"
      class="share-link__details"
    >
      <div
        role="link"
        class="share-link__url"
        :full-url="shareUrl"
        aria-label="Share Link"
        data-automation="share-link__url"
        tabindex="0"
      >
        {{ shareUrl }}
      </div>
      <CopyButton
        class="smallTextButton share-link__copy"
        data-automation="share-link__copy-button"
        :copy-text="shareUrl"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.share-link {
  margin-top: 0.9rem;

  &__details {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid #c8c8c8;
    width: 100%;
    max-width: 100%;
    padding: 0 0.6rem 0.4rem 0.6rem;
    background-color: #fff;
    color: rgb(89, 88, 88);
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    margin-top: 0.2rem;
  }

  &__url {
    font-size: 13px;
    line-height: 16px;
    margin-top: 0.5rem;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include control-visible-focus();
  }
 
  &__copy {
    margin: 5px 0 0 5px;
    padding: 2px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    background-color: $color-button-background;
    transition-property: background-color;
    transition-duration: 0.25s;
    color: $color-button-text;
    font-size: 13px;
    line-height: 30px;
    min-width: unset;

    &:hover {
      background-color: $color-button-background-hover;
      text-decoration: none;
    }
  }

  &__delete {
    &-warning {
      margin-bottom: 2.0rem;
    }

    &-confirm {
      font-weight: bold;
      text-align: center;
    }
  }
}
</style>
